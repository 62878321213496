import './App.css';
import MainRoutes from './Components/MainRoutes';

function App() {
  return (
    <>
    <div className="App">
      <MainRoutes/>
      
    </div>
    <footer>
    <a
      href="mailto:vikram@vikramsethi.com"
      style={{ textDecoration: "none" }}
    >
      Copyright  
    &copy; 2023 Disruption-sim All rights reserved
    </a>
  </footer>
  </>
  );
}

export default App;
